import { isOperator } from '../orders';
import { getDiscountAmount, getPercentageDiscount } from '../price';
import { getRealURL } from '../utils';
import { AFFILIATION_NAME_OPERATOR } from './helpers';

import type { TypeProductAlgolia } from '@/lib/graphql/types';
import type { GTMProduct } from './products';

export const normalizeAlgoliaItem = ({
  item,
  itemListName,
  index,
}: {
  item: TypeProductAlgolia;
  itemListName?: string;
  index?: number;
}): GTMProduct => {
  let regularPrice = item.regular_price;
  let salePrice = item.sale_price;
  let sku = item.sku;
  let discountAmount: number | undefined;
  let discountPercentage: number | undefined;
  const variation = item.variations?.at(0);

  if (variation) {
    regularPrice = variation.regular_price;
    salePrice = variation.sale_price;
    sku = !item.sku ? variation.sku : item.sku;
  }

  if (item.on_sale && salePrice) {
    discountAmount = Number.parseFloat(
      getDiscountAmount(regularPrice, salePrice).toFixed(2),
    );

    discountPercentage = getPercentageDiscount(regularPrice, salePrice);
  }

  const bundleItems = item.woosb_items
    ?.map((item) => item.sku)
    .filter(Boolean)
    .join('-');

  // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
  const seller = isOperator(item?.seller?.at(0))
    ? item?.seller?.at(0) || AFFILIATION_NAME_OPERATOR
    : AFFILIATION_NAME_OPERATOR;

  const categories: {
    [key: string]: string;
  } = {};

  item?.tags?.forEach((tag, i) => {
    const catLabel = i === 0 ? 'item_category' : `item_category${i + 1}`;
    categories[catLabel] = tag.name;
  });

  const listName = (window?.itemListName || itemListName || 'direct')
    .split(' ')
    .join('_');
  const indexItem = window?.itemIndex ?? index;

  return {
    item_id: sku || item.name,
    item_id_connectif: item.id,
    item_name: item.name,
    // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
    affiliation: seller,
    index: indexItem,
    ...(Array.isArray(item?.marca) &&
      item.marca.length > 1 && {
        item_brand: item.marca.at(0),
      }),
    price: item.price,
    free_item: item.price === 0 ? 'si' : 'no',
    free_item_price: item.price === 0 ? regularPrice : 0,
    original_price: regularPrice,
    discount: discountAmount,
    discount_percentage: discountPercentage
      ? `${discountPercentage}%`
      : undefined,
    quantity: 1,
    ...categories,
    item_variant: undefined,
    items_pack: bundleItems || undefined,
    item_list_name: listName,
    item_list_id: listName,
    item_custom_box: 'no',
    item_image: item.featured_image?.src || undefined,
    item_url: getRealURL(item.path),
  };
};
